.st0 {
    fill: #083246;
}

.st1 {
    fill: #083346;
}

.st2 {
    fill: #093245;
}

.st3 {
    fill: #0A3245;
}

.st4 {
    fill: #0A3244;
}

.st5 {
    fill: #5094CC;
}

.st6 {
    fill: #5295CC;
}

.st7 {
    fill: #5095CD;
}

.st8 {
    fill: #5193CB;
}

.st9 {
    fill: #5494CA;
}

.st10 {
    fill: #5495C9;
}

.st11 {
    fill: #5C96C7;
}

.st12 {
    fill: #FDFDFD;
}

.st13 {
    fill: #FEFEFE;
}